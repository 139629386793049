<template>
  <section>
    <base-card>
      <h2>Page Not found</h2>
      <p>
        This page could not be found -may be checkout all our
        <router-link to="/doctors">doctors.</router-link>
      </p>
    </base-card>
  </section>
</template>