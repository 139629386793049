export default {
    registerDoctor(state, payload) {
      state.doctors.push(payload);
    },
    setDoctors(state,payload)
    {
      state.doctors=payload;
    },
    setFetchTimestamp(state){
      state.lastFetch=new Date().getTime();
    }
  };