import { createApp } from "vue";
import Apxor from "apxor";
import CE from "apxor-qe";
import ApxorRTM from "apxor-rtm";
import router from "./router.js";
import App from "./App.vue";
import store from "./store/index.js";
import BaseCard from "./components/ui/BaseCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseBadge from "./components/ui/BaseBadge.vue";
import DoctorFilterVue from "@/components/doctors/DoctorFilter.vue";
import DoctorForm from "./components/doctors/DoctorForm.vue";
import RequestItem from "./components/requests/RequestItem.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
const app = createApp(App);
console.log("Apxor is getting initialised");
Apxor.init("8efc99fa-66aa-4700-976a-3b6a52d1f77d", {
  plugins: ["ApxorRTM"],
  deps: [ApxorRTM, CE],
});

app.use(router);
app.use(store);

app.component("base-card", BaseCard);
app.component("base-button", BaseButton);
app.component("base-badge", BaseBadge);
app.component("coach-filter", DoctorFilterVue);
app.component("doctor-form", DoctorForm);
app.component("request-item", RequestItem);
app.component("base-spinner", BaseSpinner);
app.component("base-dialog", BaseDialog);
app.mount("#app");
