<template>
  <div>
    <section>
      <base-card>
        <h2>{{ fullName }}</h2>
        <h3>${{ rate }}/hour</h3>
      </base-card>
    </section>
    <section>
      <base-card>
        <header>
          <h2>Intrested? Reach out now!</h2>
        </header>
        <router-view></router-view>
      </base-card>
    </section>
    <section>
      <base-card>
        <base-badge
          v-for="area in areas"
          :key="area"
          :type="area"
          :title="area"
        ></base-badge>
        <p>{{ description }}</p>
      </base-card>
    </section>
  </div>
</template>


<script>
export default {
  props: ["id"],
  data() {
    return {
      selectedDoctor: null,
    };
  },
  computed: {
    areas() {
      return this.selectedDoctor.area;
    },
    rate() {
      return this.selectedDoctor.hourlyRate;
    },
    description() {
      return this.selectedDoctor.description;
    },
    fullName() {
      return this.selectedDoctor.firstName + " " + this.selectedDoctor.lastName;
    },
    contactLink() {
      return this.$route.path + "/" + this.id + "/conatct";
    },
  },
  created() {
    this.selectedDoctor = this.$store.getters["doctors/doctors"].find(
      (doctor) => doctor.id === this.id
    );
  },
};
</script>