export default {
    doctors(state)
    {
        return state.doctors;
    },
    hasDoctors(state){
        return state.doctors && state.doctors.length>0;
    },
    isDoctor(_,getters,_2,rootGetters){
        const doctors=getters.doctors;
        const userId=rootGetters.userId;
        return doctors.some(doctor=>doctor.id===userId)
    },
    shouldUpdate(state){
        const lastFetch=state.lastFetch;
        if(!lastFetch)
        {
            return true;
        }
        const currentTimeStamp=new Date().getTime();
        return (currentTimeStamp-lastFetch)/1000>60;
    }
};