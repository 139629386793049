export default{
    async login(context,payload){
        const response=await fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAW7JWOpTp5qe8NUo4neqTfJ9fLag44EjU',{
            method:'POST',
            body:JSON.stringify({
                email:payload.email,
                password:payload.password,
                returnSecureToken:true
            })
        });
        const responseData=await response.json();

        if(!response.ok){
            console.log(responseData);
            const error=new Error(responseData.message||'Failed to Login, Try with correct credentials');
            throw error;
        }

        console.log(responseData);
        context.commit('setUser',{
            token:responseData.idToken,
            userId:responseData.localId,
            tokenExpiration:responseData.expiresIn
        })
    },
    async signup(context,payload){
        const response=await fetch('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAW7JWOpTp5qe8NUo4neqTfJ9fLag44EjU',{
            method:'POST',
            body:JSON.stringify({
                email:payload.email,
                password:payload.password,
                returnSecureToken:true
            })
        });
        const responseData=await response.json();

        if(!response.ok){
            console.log(responseData);
            const error=new Error(responseData.message||'Failed to SignUp, Try to login');
            throw error;
        }

        console.log(responseData);
        context.commit('setUser',{
            token:responseData.idToken,
            userId:responseData.localId,
            tokenExpiration:responseData.expiresIn
        })
    },
    logout(context){
        context.commit('setUser',{
            token:null,
            userId:null,
            tokenExpiration:null
        })
    }
}