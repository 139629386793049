export default {
  async registerDoctor(context, data) {
    const userId = context.rootGetters.userId;
    const doctorData = {
      firstName: data.first,
      lastName: data.last,
      description: data.desc,
      hourlyRate: data.rate,
      areas: data.areas
    };

    const token=context.rootGetters.token;

    const response = await fetch(`https://vue-js-test-app-ce8b1-default-rtdb.firebaseio.com/doctors/${userId}.json?auth=`+token, {
      method: 'PUT',
      body: JSON.stringify(doctorData)
    });


    if (!response.ok) {
      // error ...
    }

    context.commit('registerDoctor', {
      ...doctorData,
      id: userId
    });
  },
  async loadDoctors(context,payload){
    if(!payload.forceRefresh&&!context.getters.shouldUpdate){
      return;
    }

   const response= await fetch(`https://vue-js-test-app-ce8b1-default-rtdb.firebaseio.com/doctors.json`);
   const responseData = await response.json();
   if(!response.ok){
    //..
    const error=new Error(responseData.message || 'Failed to fetch!');
    throw error;
   }
   const doctors=[];
   for(const key in responseData)
   {
    const doctor={
      id:key,
      firstName: responseData[key].firstName,
      lastName: responseData[key].lastName,
      description:responseData[key].description,
      hourlyRate: responseData[key].hourlyRate,
      areas:responseData[key].areas
    };
    doctors.push(doctor);
   }

   context.commit('setDoctors',doctors);
   context.commit('setFetchTimestamp')
  }
};
