<template>
  <section>
    <base-card>
      <h2>Register as a Doctor now!</h2>
      <doctor-form @save-data="saveData"></doctor-form>
    </base-card>
  </section>
</template>

<script>
export default {
  methods: {
    saveData(data) {
      this.$store.dispatch("doctors/registerDoctor", data);
      this.$router.replace("/doctors");
    },
  },
};
</script>