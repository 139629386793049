import {createRouter, createWebHistory} from 'vue-router';
import DoctorDetail from './pages/doctors/DoctorDetail.vue';
import DoctorsList from './pages/doctors/DoctorsList.vue';
import DoctorRegistration from './pages/doctors/DoctorRegistration.vue';
import ContactDoctor from './pages/requests/ContactDoctor.vue';
import RequestRecieved from './pages/requests/RequestRecieved.vue';
import NotFound from './pages/NotFound.vue';
import UserAuth from './pages/auth/UserAuth.vue'

const router =createRouter({
    history:createWebHistory(),
    routes:[
        {path:'/',redirect:'/doctors'},
        {path:'/doctors',component:DoctorsList},
        {path:'/doctors/:id',component:DoctorDetail,props:true,children:[
            {path:'contact',component:ContactDoctor},//doctors/c1/contact
            
        ]},
        {path:'/register',component:DoctorRegistration},
        {path:'/requests',component:RequestRecieved},
        {path:'/auth',component:UserAuth},
        {path:'/:notFound(.*)',component:NotFound},
    ],
});

export default router;